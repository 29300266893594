<template>
    <li :class="open ? ['bg-primary-900'] : ['border-opacity-0']" class="border-t first:border-t-0 border-primary-800">
        <div @click="open = !open" class="flex justify-between cursor-pointer px-6 py-3">
            <p class="text-white text-opacity-75">
                <t :path="name" />
            </p>
            <span class="transition-transform" :class="[active ? ['text-accent-500'] : ['text-white'], open ? ['rotate-90'] : []]">
                <fa class="text-xs" :icon="['fas', `chevron-right`]" />
            </span>
        </div>

        <div v-if="open" class="px-4 pb-3">
            <slot />
        </div>
    </li>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
    props: {
        name: {
            type: [String, Array],
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const open = ref(props.active);

        return {
            open
        };
    }
});
</script>
