<template>
    <div>
        <div v-if="status !== -2" class="relative flex h-3 w-3" v-tippy="`generic.server.status.${state[1]}`">
            <div class="animate-ping absolute inset-0 inline-flex h-full w-full rounded-full opacity-75" :class="[`bg-${state[0]}`]" v-tippy="`generic.server.status.${state[1]}`"></div>
            <div class="relative inline-flex rounded-full h-3 w-3" :class="[`bg-${state[0]}`]" />
        </div>
        <div v-else class="relative flex h-3 w-3">
            <span class="text-accent-500 relative inline-flex h-3 w-3">
                <fa :icon="['fas', 'spinner']" spin fixed-width />
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { mappedState, ServerStatus } from '~/api/services/client/servers';
import { state } from '~/core';

export default defineComponent({
    props: {
        status: {
            type: Number
        },
    },
    setup(props) {
        const server = computed(() => state.models.server);

        return {
            state: computed(() => {
                if (props.status != undefined && mappedState[props.status]) return mappedState[props.status];
                if (server.value?.suspended) return mappedState[ServerStatus.Suspended];

                return mappedState[ServerStatus.Error];
            }),
            status : computed(() => props.status),
        };
    },
});
</script>
