import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCodeBranch as fasCodeBranch,
    faExclamationTriangle as fasExclamationTriangle,
    faBell as fasBell,
    faBullhorn as fasBullhorn,
    faCogs as fasCogs,
    faCog as fasCog,
    faSignOutAlt as fasSignOutAlt,
    faServer as fasServer,
    faUser as fasUser,
    faLock as fasLock,
    faLockOpen as fasLockOpen,
    faQuestionCircle as fasQuestionCircle,
    faQuestion as fasQuestion,
    faSpinner as fasSpinner,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faUpload as fasUpload,
    faExternalLinkSquareAlt as fasExternalLinkSquareAlt,
    faTimes as fasTimes,
    faBars as fasBars,
    faArrowDown as fasArrowDown,
    faTachometerAlt as fasTachometerAlt,
    faMemory as fasMemory,
    faHdd as fasHdd,
    faFolderOpen as fasFolderOpen,
    faChartArea as fasChartArea,
    faFileUpload as fasFileUpload,
    faInfoCircle as fasInfoCircle,
    faEllipsisH as fasEllipsisH,
    faFolder as fasFolder,
    faFileAlt as fasFileAlt,
    faWifi as fasWifi,
    faGamepad as fasGamepad,
    faMapMarkedAlt as fasMapMarkedAlt,
    faUsers as fasUsers,
    faEdit as fasEdit,
    faArrowRight as fasArrowRight,
    faCopy as fasCopy,
    faFileArchive as fasFileArchive,
    faDownload as fasDownload,
    faTrash as fasTrash,
    faDatabase as fasDatabase,
    faWrench as fasWrench,
    faUserPlus as fasUserPlus,
    faUserCog as fasUserCog,
    faNetworkWired as fasNetworkWired,
    faFingerprint as fasFingerprint,
    faBoxes as fasBoxes,
    faCalendarAlt as fasCalendarAlt,
    faPlug as fasPlug,
    faCubes as fasCubes,
    faPlus as fasPlus,
    faChevronRight as fasChevronRight,
    faSearch as fasSearch,
    faPencilAlt as fasPencilAlt,
    faDoorOpen as fasDoorOpen,
    faStopwatch as fasStopwatch,
    faPlayCircle as fasPlayCircle,
    faHome as fasHome,
    faRandom as fasRandom,
    faGlobe as fasGlobe,
    faHeartbeat as fasHeartbeat,
    faCube as fasCube,
    faEgg as fasEgg,
    faUserAlt as fasUserAlt,
    faKey as fasKey,
    faPowerOff as fasPowerOff,
    faIdBadge as fasIdBadge,
    faStar as fasStar,
    faExchangeAlt as fasExchangeAlt,
    faSync as fasSync,
    faBook as fasBook,
    faComment as fasComment,
    faTicket as fasTicket,
    faFileCirclePlus as fasFileCirclePlus,
    faFolderPlus as fasFolderPlus,
    faFloppyDisk as fasFloppyDisk,
    faMicrochip as fasMicroChip,
    faX as fasX,
    faStop as fasStop,
    faSkull as fasSkull, //anthony moment
    faTerminal as fasTerminal,
} from '@fortawesome/free-solid-svg-icons';

import {
    faHeart as farHeart,
    faClock as farClock,
    faPaste as farPaste,
} from '@fortawesome/free-regular-svg-icons';

import {
    faSteam as fabSteam,
    faGit as fabGit,
    faDiscord as fabDiscord,
    faGitSquare as fabGitSquare,
} from '@fortawesome/free-brands-svg-icons';
library.add(
    /* SOLID */
    fasCodeBranch, fasExclamationTriangle, fasBell, fasBullhorn, fasCogs, fasCog, fasSignOutAlt, fasServer, fasUser, fasLock, fasLockOpen,
    fasQuestionCircle, fasQuestion, fasSpinner, fasCheck, fasCheckCircle, fasUpload, fasExternalLinkSquareAlt, fasTimes, fasBars,
    fasArrowDown, fasTachometerAlt, fasMemory, fasHdd, fasFolderOpen, fasChartArea, fasFileUpload, fasInfoCircle, fasEllipsisH, fasFolder,
    fasFileAlt, fasWifi, fasGamepad, fasMapMarkedAlt, fasUsers, fasEdit, fasArrowRight, fasCopy, fasFileArchive, fasDownload,
    fasTrash, fasDatabase, fasWrench, fasUserPlus, fasUserCog, fasNetworkWired, fasFingerprint, fasBoxes, fasCalendarAlt, fasPlug,
    fasCubes, fasPlus, fasChevronRight, fasSearch, fasPencilAlt, fasDoorOpen, fasStopwatch, fasPlayCircle, fasHome, fasRandom,
    fasGlobe, fasHeartbeat, fasCube, fasEgg, fasUserAlt, fasKey, fasPowerOff, fasIdBadge, fasStar, fasExchangeAlt, fasSync, fasBook,
    fasComment, fasTicket, fasFileCirclePlus, fasFolderPlus, fasFloppyDisk, fasMicroChip, fasX, fasStop, fasSkull, fasTerminal,
    /* REGULAR */
    farHeart, farClock, farPaste,

    /* BRANDS */
    fabSteam, fabGit, fabDiscord, fabGitSquare
);
